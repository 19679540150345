import React, { useEffect, useState } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";

const ReactGoogleMap = withScriptjs(withGoogleMap((props) =>{
    const [mapData, setMapData] = useState([])
    const [segmentsCodes, setSegmentsCodes] = useState([])
    const [segmentsCodesArr, setSegmentsCodesArr] = useState([])
    const [singleItem, setSingleItem] = useState()
    const [lat, setLat] = useState(0)
    const [lng, setLng] = useState(0)

    useEffect(() => {
        const listener = e => {
           if (e.key === "Escape") {
            setSingleItem({vlLatitude: lat, vlLongitude: lng});
           }
        };
        window !== 'undefined' && window.addEventListener("keydown", listener);
        return () => {
            window !== 'undefined' && window.removeEventListener("keydown", listener);
        };
     }, []);

     
     useEffect(()=>{
        let codes = []
        segmentsCodes.map(({cdSegment})=>codes.push(cdSegment))
        setSegmentsCodesArr(codes)
    }, [segmentsCodes])     

    useEffect(()=>{
     (async ()=>{
        var myHeaders = new Headers();
        myHeaders.append("accept", "*/*");
        myHeaders.append("content-type", "application/json");
        myHeaders.append("X-QZelaAccessToken", `${process.env.GATSBY_ACCESS_TOKEN}`);
        myHeaders.append("X-QZelaUserId", `${process.env.GATSBY_USER_ID}`);
        var graphql = JSON.stringify({
            query: `query{
              getIncidentsByPolygonIdOrViewport(polygonId: "5d35c7712790c277d7acd071", segments: ${segmentsCodesArr}, isOpen: true) {
                data {
                    _id
                    vlLatitude
                    vlLongitude
                    stIncident
                    segments { 
                        cdSegment
                        dcSegment
                        dcOccurrence
                    }
                    mediaUrls
                }
            }
          }`
        })
        var graphqlCodes = JSON.stringify({
            query: `query{
                getSegmentsCodes{
                    _id
                    cdSegment
                    stActive
                }
          }`
        })
        const requestOptionsCodes = {
            method: 'POST',
            headers: myHeaders,
            body: graphqlCodes,
            redirect: 'follow'
        };
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: graphql,
            redirect: 'follow'
        };

        await fetch(`${process.env.GATSBY_API_URL}`, requestOptionsCodes)
            .then(response => response.json())
            .then(result => {
                setSegmentsCodes(result?.data?.getSegmentsCodes)
            })
            .catch(error => console.log('error', error));
        await fetch(`${process.env.GATSBY_API_URL}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setMapData(result?.data?.getIncidentsByPolygonIdOrViewport.data)
            })
            .catch(error => console.log('error', error));
     })()  
    }, [segmentsCodesArr.length])
    const google = window !== 'undefined' && window.google
    const mapStyles = [
        {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [{visibility: "off"}]
        },
        {
            featureType: "landscape",
            stylers: [{visibility: "off"}]
        },
        {
            featureType: "poi",
            stylers: [{visibility: "off"}]
        },
        {
            featureType: "road",
            stylers: [{visibility: "on"}]
        },
        {
            "featureType": "road.local",
            "elementType": "labels",
            stylers: [{visibility: "off"}]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels",
            stylers: [{visibility: "off"}]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels",
            stylers: [{visibility: "on"}]
        },
        {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [{visibility: "off"}]
        },
        {
            featureType: "transit",
            stylers: [{visibility: "off"}]
        }
    ];

    const configPos = (item) => {
        setSingleItem(item)
        setLat(item.vlLatitude)
        setLng(item.vlLongitude)
    }

    return(
        <GoogleMap zoom={4} center={ { 
            lat: lat ? lat : -14.235004,
            lng: lng ? lng : -51.92528
        } }
            options={{
                styles: mapStyles
            }}
        > 
            {mapData?.map(item => (
                <Marker
                    icon={{
                        url: `https://storage.googleapis.com/assets.qzela.com.br/images/markers/${item.segments[0].cdSegment}.png`,
                        scaledSize: new google.maps.Size(38, 65),
                        Size: 65
                    }}
                    key={item._id}
                    position={{
                        lat: item.vlLatitude,
                        lng: item.vlLongitude
                    }}
                    onClick={() => configPos(item)}
                />
            ))}
            {singleItem && 
                <InfoWindow
                   onCloseClick={() => {
                        setSingleItem('');
                   }}
                   position={{ 
                      lat: singleItem.vlLatitude,
                      lng: singleItem.vlLongitude
                   }}
                   options={{pixelOffset: window !== 'undefined' && new window.google.maps.Size(0,-65)}}
                   className="infowindow"

                >
                    <>
                    {singleItem.segments[0] && 
                        <>

                            <h3>{singleItem.segments[0].dcSegment}</h3>
                            <p>
                                {singleItem.segments[0].dcOccurrence}
                            </p>
                        </>                   
                    }
                    {singleItem.mediaUrls[0] &&
                        <div style={{width: 300, height: 200, backgroundColor: '#000'}}>
                            <img src={singleItem.mediaUrls[0]} style={{width: '100%', height: '100%', objectFit: 'contain', objectPosition: 'center'}} alt=""/>
                        </div>
                    }
                    </>
                </InfoWindow>
             }
             
        </GoogleMap>
    )
}))

 export default ReactGoogleMap;
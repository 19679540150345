import React, {useContext} from "react"
import SEO from "../components/seo"
import {GlobalStateContext} from '../context/GlobalContextProvider'
import LayoutMaster from "../components/layout"
import '../styles/global.css'
import ReactGoogleMap from '../components/map'

function Map({data, location}){
    const state = useContext(GlobalStateContext)
    const {menu} = state.language


    return(
        <LayoutMaster location={location}>
          <SEO title={menu[9].text} />
            <ReactGoogleMap
                isMarkerShown
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `calc(100vh - 150px)` }} />}
                mapElement={<div style={{ height: `100%` }} />}
            />
        </LayoutMaster>
    )
}

export default Map

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `